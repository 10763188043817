<template>
  <div>
    <div class="flex flex-wrap" v-if="!loadingRecent">
      <div class="text-center w-full" v-if="detailTicket === undefined || detailTicket.length ===0">
        <span class="font-medium text-base">No Ticket Available</span>
      </div>
      <div class="relative w-full md:w-1/2" v-else v-for="data in detailTicket" :key="data.new_event.id_event">
        <CardPastTicket :tTt="data.new_event.judul_event" :tDt="data.new_event.tanggal_event"
          :tTm="data.new_event.jam_event" :tLc="data.new_event.alamat_event" :tIm="data.new_event.foto_event" />
      </div>
    </div>
    <div class="flex flex-wrap" v-else>
      <div class="relative w-full md:w-1/2">
        <img class="transform translate-y-1 translate-x-1 transition-all duration-300 animate-pulse" src="@/assets/voucher-event.svg" alt="" />
        <div class="absolute z-30 top-1/2 left-12 inline-block transform -translate-y-1/2">
          <div class="flex items-center">
            <div class="sm:w-20 w-14 sm:h-20 h-14 rounded-full bg-gray-200 transition-all duration-300 animate-pulse"></div>
            <div class="ml-8 text-xs w-60">
              <div class="rounded-10 h-4 bg-gray-200 cursor-pointer sm:w-60 w-40 transition-all duration-300 animate-pulse my-1">
              </div>
              <div class="rounded-10 h-4 bg-gray-200 cursor-pointer w-32 transition-all duration-300 animate-pulse my-1">
              </div>
              <div class="rounded-10 h-4 bg-gray-200 cursor-pointer w-28 transition-all duration-300 animate-pulse my-1">
              </div>
              <div class="rounded-10 h-4 bg-gray-200 cursor-pointer w-40 transition-all duration-300 animate-pulse my-1">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="relative w-full md:w-1/2">
        <img class="transform translate-y-1 translate-x-1 transition-all duration-300 animate-pulse" src="@/assets/voucher-event.svg" alt="" />
        <div class="absolute z-30 top-1/2 left-12 inline-block transform -translate-y-1/2">
          <div class="flex items-center">
            <div class="sm:w-20 w-14 sm:h-20 h-14 rounded-full bg-gray-200 transition-all duration-300 animate-pulse"></div>
            <div class="ml-8 text-xs w-60">
              <div class="rounded-10 h-4 bg-gray-200 cursor-pointer sm:w-60 w-40 transition-all duration-300 animate-pulse my-1">
              </div>
              <div class="rounded-10 h-4 bg-gray-200 cursor-pointer w-32 transition-all duration-300 animate-pulse my-1">
              </div>
              <div class="rounded-10 h-4 bg-gray-200 cursor-pointer w-28 transition-all duration-300 animate-pulse my-1">
              </div>
              <div class="rounded-10 h-4 bg-gray-200 cursor-pointer w-40 transition-all duration-300 animate-pulse my-1">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="relative w-full md:w-1/2">
        <img class="transform translate-y-1 translate-x-1 transition-all duration-300 animate-pulse" src="@/assets/voucher-event.svg" alt="" />
        <div class="absolute z-30 top-1/2 left-12 inline-block transform -translate-y-1/2">
          <div class="flex items-center">
            <div class="sm:w-20 w-14 sm:h-20 h-14 rounded-full bg-gray-200 transition-all duration-300 animate-pulse"></div>
            <div class="ml-8 text-xs w-60">
              <div class="rounded-10 h-4 bg-gray-200 cursor-pointer sm:w-60 w-40 transition-all duration-300 animate-pulse my-1">
              </div>
              <div class="rounded-10 h-4 bg-gray-200 cursor-pointer w-32 transition-all duration-300 animate-pulse my-1">
              </div>
              <div class="rounded-10 h-4 bg-gray-200 cursor-pointer w-28 transition-all duration-300 animate-pulse my-1">
              </div>
              <div class="rounded-10 h-4 bg-gray-200 cursor-pointer w-40 transition-all duration-300 animate-pulse my-1">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="relative w-full md:w-1/2">
        <img class="transform translate-y-1 translate-x-1 transition-all duration-300 animate-pulse" src="@/assets/voucher-event.svg" alt="" />
        <div class="absolute z-30 top-1/2 left-12 inline-block transform -translate-y-1/2">
          <div class="flex items-center">
            <div class="sm:w-20 w-14 sm:h-20 h-14 rounded-full bg-gray-200 transition-all duration-300 animate-pulse"></div>
            <div class="ml-8 text-xs w-60">
              <div class="rounded-10 h-4 bg-gray-200 cursor-pointer sm:w-60 w-40 transition-all duration-300 animate-pulse my-1">
              </div>
              <div class="rounded-10 h-4 bg-gray-200 cursor-pointer w-32 transition-all duration-300 animate-pulse my-1">
              </div>
              <div class="rounded-10 h-4 bg-gray-200 cursor-pointer w-28 transition-all duration-300 animate-pulse my-1">
              </div>
              <div class="rounded-10 h-4 bg-gray-200 cursor-pointer w-40 transition-all duration-300 animate-pulse my-1">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PastTicket',
  components: {
    CardPastTicket: () => import('@/components/CardPastTicket.vue'),
  },
  data() {
    return {
      loadingRecent: false,
      detailTicket: {}
    }
  },
  methods: {
    async getTicket() {
      this.loadingRecent = true
      try {
        const res = await this.$http.post(`${this.$apiTripweWeb}/event/ticket`, {
          txtTelepon: this.$store.state.user.telepon,
          txtMenu: 'past',
        })
        const data = res.data.response
        this.detailTicket = data
      } catch (error) {
        this.$toasted.global.error(error);
      } finally {
        this.loadingRecent = false
      }
    }
  },
  created() {
    this.getTicket()
  },
}
</script>